.signin-background {
    background-image: url('https://cdn.dribbble.com/users/41854/screenshots/2042556/media/c3c3a145c9e7637f4fa60e20224225ca.gif'); /* Update this path */
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  